import { useSessionStorageValue } from '@react-hookz/web';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export const usePersistedScrollPosition = <T extends HTMLElement>(key: string, recover = true) => {
  const {
    value: scrollPosition,
    set: setScrollPosition,
    remove: removeScrollPosition,
  } = useSessionStorageValue<number | null>(key);

  const [initialScrollPosition] = useState(scrollPosition);

  const [scrollElement, setScrollElement] = useState<T | null>(null);

  const setScrollRef = useCallback((node: T) => {
    if (node !== null) {
      setScrollElement(node);
    }
  }, []);

  useEffect(() => {
    if (!scrollElement) return;

    if (recover) {
      scrollElement.scrollTop = initialScrollPosition ?? 0;
    } else {
      removeScrollPosition();
    }

    const scrollHandler = debounce(() => {
      setScrollPosition(scrollElement.scrollTop ?? 0);
    }, 200);

    scrollElement.addEventListener('scroll', scrollHandler);

    return () => {
      scrollElement.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollElement, recover, initialScrollPosition, removeScrollPosition, setScrollPosition]);

  return { setScrollRef };
};

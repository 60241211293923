'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import type { GetQuestboardOutput } from '@zealy/utils';
import { Badge } from '@zealy/design-system';

import { ModuleSection } from './ModuleSection';

export const SectionView = ({
  questboard,
  isSubscriptionLimitReached,
}: {
  questboard: GetQuestboardOutput;
  isSubscriptionLimitReached?: boolean;
}) => {
  const t = useTranslations('questboard');

  const indexLockedStart = questboard?.findIndex(module => module.status === 'locked');
  const indexCompletedStart = questboard?.findIndex(module => module.status === 'completed');

  return (
    <div className="flex flex-col">
      {questboard?.map((module, index) => (
        <React.Fragment key={module.id}>
          {indexCompletedStart === index && (
            <Badge className="z-[1] bg-secondary -mb-150 mx-auto" variant="disabled">
              {t('modules-section', {
                status: t('badge.statuses.completed'),
              })}
            </Badge>
          )}
          {indexLockedStart === index && (
            <Badge className="z-[1] bg-secondary -mb-150 mx-auto" variant="disabled">
              {t('modules-section', {
                status: t('badge.statuses.lock'),
              })}
            </Badge>
          )}
          <ModuleSection module={module} isSubscriptionLimitReached={isSubscriptionLimitReached} />
        </React.Fragment>
      ))}
    </div>
  );
};

'use client';

import { useCallback, useMemo } from 'react';

import { useQuestBoard } from '@zealy/queries';

import { getQuestStatus } from '#components/QuestCard/components/QuestStatus/QuestStatus.utils';

export const useNextQuest = (moduleId?: string, questId?: string) => {
  const { data } = useQuestBoard();

  const getNextQuest = useCallback(
    (moduleIndex: number, questPosition: number) => {
      const questModule = data?.at(moduleIndex);
      if (!questModule) {
        return undefined;
      }

      const nextQuest = questModule.quests?.find(q => q.position > questPosition);
      if (!nextQuest) {
        return getNextQuest(moduleIndex + 1, 0);
      }

      const status = getQuestStatus(nextQuest, false);

      if (!status.canClaim) {
        return getNextQuest(moduleIndex, nextQuest.position);
      }

      return nextQuest;
    },
    [data],
  );

  return useMemo(() => {
    if (!moduleId || !data) return undefined;

    const currentModuleIndex = data?.findIndex(m => m.id === moduleId) ?? -1;

    if (currentModuleIndex === -1) return undefined;

    const currentQuest = data.at(currentModuleIndex)?.quests.find(q => q.id === questId);

    const currentQuestPosition = currentQuest?.position ?? 0;

    return getNextQuest(currentModuleIndex, currentQuestPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId, questId, getNextQuest]);
};

'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import { Button } from '@zealy/design-system';

import { useCommunityURL } from '#hooks/useCommunityURL';
import { useNextQuest } from '#hooks/useNextQuest';
import { cn } from '#utils/utils';

import { useQuestboardContext } from './QuestboardContext';

export const EmptyState = ({
  view = 'board',
  className = '',
  totalQuestCount,
}: {
  view?: 'board' | 'module';
  className?: string;
  totalQuestCount?: number;
}) => {
  const t = useTranslations();
  const tS = useTranslations('questboard.search');

  const { moduleId, questId } = useParams<{ moduleId: string; questId: string }>();

  const nextQuest = useNextQuest(moduleId, questId);
  const baseURL = useCommunityURL();
  const nextQuestURL = `${baseURL}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`;

  const { setSelectedCategories, clearFilters } = useQuestboardContext();

  const clearAllFilters = () => {
    setSelectedCategories([]);
    clearFilters();
  };

  return (
    <div
      className={cn(
        'flex flex-1 flex-col bg-secondary overflow-hidden rounded-md p-400 items-center justify-center gap-300',
        className,
      )}
    >
      <Image width={400} height={400} src="/nstatic/careers/grow.webp" alt="" />
      <p className="body-paragraph-lg text-secondary max-w-[420px] text-center min-w-0">
        {t(`questboard.empty.${view}`)}
      </p>

      {nextQuest && (
        <Button as={Link} href={nextQuestURL} variant="muted">
          {t('common.next-quest')}
        </Button>
      )}

      {!!totalQuestCount && (
        <div className="w-full max-w-[350px] border rounded-component-sm p-component-sm flex items-center justify-between">
          <p className="body-component-md text-secondary">
            {tS.rich('hidden-quests', {
              b: children => <b className="body-component-md-bold text-primary">{children}</b>,
              count: totalQuestCount,
            })}
          </p>
          <Button size="xs" variant="muted" onClick={clearAllFilters}>
            {tS('clear')}
          </Button>
        </div>
      )}
    </div>
  );
};

'use client';

import React from 'react';

import { canUseDOM, GetQuestboardOutput } from '@zealy/utils';

import { Module } from './Module';

export const GridView = ({
  questboard,
  isSubscriptionLimitReached,
}: {
  questboard: GetQuestboardOutput;
  isSubscriptionLimitReached?: boolean;
}) => {
  const hasOneColumn = canUseDOM() && window.matchMedia('(max-width: 1280px)').matches;

  const showInFirstColumn = (index: number) => {
    return hasOneColumn ? index < Number(questboard?.length) / 2 : !(index % 2);
  };

  return (
    <div className="justify-center flex flex-col xl:flex-row flex-wrap gap-600 xl:gap-1000 mx-auto py-600 max-w-[942px] w-full">
      <div className="flex flex-1 flex-col gap-600 xl:gap-1000 xl:max-w-[428px] w-full min-w-0">
        {questboard && (
          <>
            {questboard.map((category, i) => {
              if (!showInFirstColumn(i)) return null;

              return (
                <Module
                  key={category.id}
                  category={category}
                  isSubscriptionLimitReached={isSubscriptionLimitReached}
                />
              );
            })}
          </>
        )}
      </div>
      <div className="flex flex-1 flex-col gap-600 xl:gap-1000 pt-1000 xl:max-w-[428px]">
        {questboard && (
          <>
            {questboard.map((category, i) => {
              if (showInFirstColumn(i)) return null;

              return (
                <Module
                  key={category.id}
                  category={category}
                  isSubscriptionLimitReached={isSubscriptionLimitReached}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

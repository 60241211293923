'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import { TabItem, Tabs } from '@zealy/design-system';
import { Timer2Line } from '@zealy/icons';
import { useCurrentSprint } from '@zealy/queries';

import { useIsMobile } from '#hooks/useIsMobile';

import { useQuestboardContext } from './QuestboardContext';

export const MobileTabs = () => {
  const t = useTranslations();
  const isMobile = useIsMobile();
  const sprint = useCurrentSprint();
  const { sprintView, setSprintView } = useQuestboardContext();

  if (!isMobile || !sprint.data) return null;

  return (
    <Tabs orientation="horizontal" value={sprintView ? 'sprint' : 'all'}>
      <TabItem
        value="all"
        as="button"
        selected={!sprintView}
        label={t('sidebar.items.general')}
        onClick={() => setSprintView(false)}
      />

      <TabItem
        leftIcon={<Timer2Line />}
        value="sprint"
        as="button"
        selected={sprintView}
        label={t('sidebar.items.sprints')}
        onClick={() => setSprintView(true)}
      />
    </Tabs>
  );
};

'use client';

import React from 'react';

import type { GetQuestboardOutput, QuestContributorFilters } from '@zealy/utils';
import { useSubscriptionStatus } from '@zealy/queries';

import Skeletons from '#components/Skeletons';
import { useDidMount } from '#hooks/useDidMount';
import { useSessionRecorder } from '#hooks/useSessionRecorder';

import { useFilteredQuestboard } from './Board.hooks';
import { EmptyState } from './EmptyState';
import { GridView } from './GridView/GridView';
import { useQuestboardContext } from './QuestboardContext';
import { SectionView } from './SectionView/SectionView';

const questFilters = {
  byId: (allowedIds: string[]) => (quest: GetQuestboardOutput[number]['quests'][number]) =>
    allowedIds.includes(quest.id),
};

export const useQuestboardFilters = (
  board?: GetQuestboardOutput,
  categoryIds?: string[],
  questIds?: string[],
) => {
  return React.useMemo(
    () =>
      (board ?? []).reduce(
        (acc, curr) => {
          const quests = questIds ? curr.quests.filter(questFilters.byId(questIds)) : curr.quests;

          const currentModule = {
            ...curr,
            quests,
          };

          const shouldIncludeModule =
            quests.length && (!categoryIds?.length || categoryIds.includes(curr.id));

          const modules = shouldIncludeModule ? [...acc.modules, currentModule] : acc.modules;

          return {
            totalQuestCount: acc.totalQuestCount + curr.totalQuestCount,
            modules,
          };
        },
        {
          modules: [] as GetQuestboardOutput,
          totalQuestCount: 0,
        },
      ),
    [board, categoryIds, questIds],
  );
};

const BoardSkeleton = ({ gridView = false }) =>
  gridView ? (
    <Skeletons.ModuleList totalOfSkeletons={3} />
  ) : (
    <Skeletons.SectionView totalOfSkeletons={2} />
  );

export interface BoardViewProps {
  filters?: Array<QuestContributorFilters>;
}

export const BoardView = ({ filters }: BoardViewProps) => {
  useSessionRecorder();
  const didMount = useDidMount();
  const { gridView } = useQuestboardContext();
  const { data: subscriptionData } = useSubscriptionStatus();

  const {
    isLoading,
    modules: filteredQuestboard,
    totalQuestCount,
  } = useFilteredQuestboard({
    customFilters: filters,
  });

  if (!didMount) return null;

  if (isLoading) return <BoardSkeleton gridView={gridView} />;

  if (filteredQuestboard?.length === 0) return <EmptyState totalQuestCount={totalQuestCount} />;

  return (
    <div className="flex flex-col w-full pt-100">
      {gridView ? (
        <GridView
          questboard={filteredQuestboard}
          isSubscriptionLimitReached={subscriptionData?.isLimitReached}
        />
      ) : (
        <SectionView
          questboard={filteredQuestboard}
          isSubscriptionLimitReached={subscriptionData?.isLimitReached}
        />
      )}
    </div>
  );
};

'use client';

/*
  From Datadog documentation: https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm
  Because the RUM SDK needs to run on the client to collect telemetry data, the file where it is initialized through the NPM package must be a client component.
*/

/*
  Insert this component everywhere you want to record user session.
*/
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { envConfig } from '#app/config';

export const useSessionRecorder = () =>
  useEffect(() => {
    if (typeof window !== 'undefined' && envConfig.env === 'production') {
      datadogRum.startSessionReplayRecording();
    }
  }, []);

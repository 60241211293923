'use client';

import React from 'react';

import { useNavigationHistory } from '#context/NavigationHistory';
import { usePersistedScrollPosition } from '#hooks/usePersistedScrollPosition';

import { BoardHeader } from './_components/BoardHeader';
import { StatusFilters } from './_components/BoardHeader/StatusFilters';
import { BoardView } from './_components/BoardView';
import { MobileTabs } from './_components/MobileTabs';
import { SubscriptionLimitReachedBanner } from './admin/_components/SubscriptionLimitReachedBanner';
import { AdminPreviewBanner } from './AdminPreviewBanner';

type CommunityLayoutProps = {
  params: {
    subdomain: string;
  };
};

export default function QuestBoardPage({ params: { subdomain } }: CommunityLayoutProps) {
  const { previousRoute } = useNavigationHistory();

  const shouldRecoverScroll =
    previousRoute?.includes(`/cw/${subdomain}/questboard/`) && !previousRoute?.includes('/admin');

  const { setScrollRef } = usePersistedScrollPosition<HTMLDivElement>(
    'questboard:scrollTop',
    shouldRecoverScroll,
  );

  return (
    <div className="flex flex-col gap-100 w-full min-h-0 h-full flex-1">
      <AdminPreviewBanner subdomain={subdomain} />

      <SubscriptionLimitReachedBanner subdomain={subdomain} />

      <StatusFilters className="fixed md:hidden self-end top-100 right-150 z-50" variant="ghost" />
      <div
        ref={setScrollRef}
        className="flex flex-col bg-secondary rounded-md overflow-y-auto h-full min-h-0"
      >
        <BoardHeader />
        <MobileTabs />
        <BoardView />
      </div>
    </div>
  );
}

'use client';

import React, { useCallback, useRef } from 'react';
import { useScroll } from 'react-use';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { Button, IconButton, Skeleton, TooltipProvider } from '@zealy/design-system';
import { ArrowLeftLine, ArrowRightLine, Elements5Filled, Grid04Filled } from '@zealy/icons';
import { useQuestBoard } from '@zealy/queries';

import { useDidMount } from '#hooks/useDidMount';

import { useQuestboardContext } from '../QuestboardContext';
import { CategoryFilters } from './CategoryFilters';
import { StatusFilters } from './StatusFilters';

const FilterSkeleton = () => (
  <>
    {[...Array(6)].map((_, i) => (
      <Skeleton key={i} className="rounded-tag-sm h-tag-sm w-1400" />
    ))}
  </>
);

export const BoardHeader = () => {
  const t = useTranslations('questboard.filters');
  const scrollRef = useRef<HTMLDivElement>(null);
  const { x } = useScroll(scrollRef);
  const { gridView, setGridView, filters } = useQuestboardContext();
  const questboard = useQuestBoard(filters);
  const didMount = useDidMount();

  const scrollRight = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }, [scrollRef]);

  const scrollLeft = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }, [scrollRef]);

  return (
    <div className="hidden md:flex p-200 rounded-md bg-tertiary md:sticky top-0 z-10">
      {x !== 0 && (
        <Button
          onClick={scrollLeft}
          aria-label={t('scroll-left')}
          className="hidden md:flex z-[1]"
          variant="muted"
          onlyIcon
          size="sm"
          leftIcon={<ArrowLeftLine />}
        />
      )}
      <div
        className="flex gap-100 min-w-0 overflow-x-auto flex-1 no-scrollbar pr-200"
        ref={scrollRef}
      >
        {questboard.isLoading ? <FilterSkeleton /> : <CategoryFilters />}
      </div>
      {x + 15 < Number(scrollRef.current?.scrollWidth) - Number(scrollRef.current?.clientWidth) && (
        <Button
          onClick={scrollRight}
          aria-label={t('scroll-right')}
          className="hidden md:flex z-[1]"
          variant="muted"
          onlyIcon
          size="sm"
          leftIcon={<ArrowRightLine />}
        />
      )}

      <TooltipProvider>
        <StatusFilters className="mr-100 ml-400" size="sm" variant="muted" />

        {didMount && (
          <div className="flex">
            <IconButton
              icon={<Elements5Filled />}
              tooltip={t('grid')}
              variant="muted"
              className={clsx(
                'rounded-r-none',
                !gridView ? '' : 'bg-component-quaternary-press border-component-quaternary-press',
              )}
              size="sm"
              mutedText={!gridView}
              onClick={() => setGridView(true)}
            />
            <IconButton
              icon={<Grid04Filled />}
              tooltip={t('section')}
              variant="muted"
              className={clsx(
                'rounded-l-none',
                gridView ? '' : 'bg-component-quaternary-press border-component-quaternary-press',
              )}
              size="sm"
              mutedText={gridView}
              onClick={() => setGridView(false)}
            />
          </div>
        )}
      </TooltipProvider>
    </div>
  );
};

'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button, Icon } from '@zealy/design-system';
import { InfoLine } from '@zealy/icons';
import { CommunityMember, useAuthenticatedUser, useSubscriptionStatus } from '@zealy/queries';
import { dayjs, roleIsAtLeast } from '@zealy/utils';

export const SubscriptionLimitReachedBanner = ({ subdomain }: { subdomain: string }) => {
  const user = useAuthenticatedUser<CommunityMember>();
  const t = useTranslations('questboard.limit-reached');

  const subscriptionStatus = useSubscriptionStatus({
    enabled: !!user.data?.role,
  });

  const baseHref = `/cw/${subdomain}/settings/plans`;

  // When there is no role, we show the join community banner already
  if (!user.data?.role || !subscriptionStatus.data || !subscriptionStatus.data.isLimitReached)
    return null;

  return (
    <div className="py-200 px-300 rounded-md bg-tertiary flex flex-col md:flex-row flex-shrink-0 mb-[8]">
      <div className="flex flex-row gap-150 items-center ">
        <Icon icon={<InfoLine />} className="invisible lg:visible" />
        <p className="body-paragraph-md text-primary">
          {roleIsAtLeast(user.data.role, 'admin')
            ? t('admin.label')
            : t('user.label', {
                days: dayjs(subscriptionStatus.data?.resetAt).diff(dayjs(), 'days'),
              })}
        </p>
      </div>
      <div className="flex gap-150 ml-auto">
        {roleIsAtLeast(user.data.role, 'admin') && (
          <Button variant="muted" size="sm" as={Link} href={baseHref}>
            {t('admin.upgrade')}
          </Button>
          // Add the notify CTA once the feature is ready
        )}
      </div>
    </div>
  );
};

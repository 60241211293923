'use client';

import React, { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { usePathname, useSearchParams } from 'next/navigation';

import { createContext } from '../createContext';

type NavigationHistoryContext = { previousRoute: string | null };

const defaultValue = { previousRoute: null };

const [NavigationHistoryContextProvider, useNavigationHistory] =
  createContext<NavigationHistoryContext>({
    name: 'NavigationHistoryProvider',
    hookName: 'useNavigationHistory',
    defaultValue,
  });

export const NavigationHistoryProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentRoute, setCurrentRoute] = React.useState<string | null>(null);
  const previousRoute = usePrevious(currentRoute) ?? null;

  const pathname = usePathname();
  const searchParams = useSearchParams().toString();

  useEffect(() => {
    const route = `${pathname}${searchParams ? `?${searchParams}` : ''}`;
    setCurrentRoute(route);
  }, [pathname, searchParams, previousRoute]);

  return (
    <NavigationHistoryContextProvider value={{ previousRoute }}>
      {children}
    </NavigationHistoryContextProvider>
  );
};

export { useNavigationHistory };
